
<template>
  <div id="page-user-list">
    <div id="users-table-loading" class="vx-card p-6 vs-con-loading__container">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE5 -->
          <div class="flex-grow">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span v-if="pageData.total" class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ pageData.total - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : pageData.total }} of {{ pageData.total }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 50">
                  <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 100">
                  <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 200">
                  <span>200</span>
              </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" 
          v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Delete</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="usersData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        domLayout='autoHeight'
        :pagination="false"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @cell-clicked="onCellClicked">
      </ag-grid-vue>

      <vs-pagination
        :total="pageData.last_page || 1"
        :max="7"
        v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import vSelect from 'vue-select'
// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue"
import CellRendererNameLink from "./cell-renderer/CellRendererNameLink.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue"
import CellRendererVerified from "./cell-renderer/CellRendererVerified.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import _ from 'lodash';

export default {
  components: {
    AgGridVue,
    vSelect,
    // Cell Renderer
    CellRendererLink,
    CellRendererNameLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
  },
  data() {
    return {
      // Filter Options
      defaultRole: "buyer",
      searchQuery: "",
      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 125,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'Name',
          field: 'first_name',
          filter: true,
          width: 200,
          cellRendererFramework: 'CellRendererNameLink'
        },
        {
          headerName: 'Email',
          field: 'email',
          filter: true,
          width: 225,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'Country',
          field: 'country.name',
          filter: true,
          width: 150,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'Role',
          field: 'role',
          filter: true,
          width: 150,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'Verified',
          field: 'is_verified',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererVerified',
          cellClass: "text-center"
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 200,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererNameLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      }
    }
  },
  watch: {
    "$route.query":"refreshQueryUserType",
    defaultRole (val) {
      this.fetchUsers()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.userList.pageData
    },
    usersData() {
      return this.pageData.data.filter((u) => this.defaultRole == "all" || u.role == this.defaultRole)
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchUsers(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchUsers(1, val)
      }
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    selectedIds(){
      return [...this.selectedData.map(node => node.id)];
    }
  },
  methods: {
    refreshQueryUserType(){
      if(this.$route.query.type){
        this.defaultRole = this.$route.query.type
      }
    },
    onCellClicked(params){
      if(params.data.id == this.activeUser.id){
          this.$router.push({path: '/account'})
      }else{
        this.$router.push({path: `/users/${params.data.id}`})
      }
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()
      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = { label: 'All', value: 'all' }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchUsers()
    }, 2000),
    fetchUsers(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultRole != 'all'){ payload.role = this.defaultRole }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#users-table-loading', color: "#444", type: "sound"})
      this.$store.dispatch("userList/fetchUsers", payload)
      .then(response => {
        this.$vs.loading.close('#users-table-loading > .con-vs-loading')
     })
    },
    getSelectedRows() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map( node => node.data );
      const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ');
      // alert(`Selected nodes: ${selectedDataStringPresentation}`);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api
    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)

    if(this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
      header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
    }
    this.refreshQueryUserType();
    if(this.defaultRole == "buyer"){this.fetchUsers()}
  },
  created() {
    this.$store.dispatch("userList/fetchRoles")
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
